import {Button, Card, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {
    addKilometersRequest,
    deleteSubCategory,
    editCategoryName,
    editSubCategoryName
} from "../../requests/categories/categories_request";
import {API_URL} from "../../requests/constants";
import DeleteIcon from '@mui/icons-material/Delete';
import {CustomModal} from "../common/CustomModal";
import EditIcon from "@mui/icons-material/Edit";


export const SubCategoryKMView = (props) => {
    const [name, setName] = useState(props.value.name)
    const [totalNumberOfKilometer, setTotalNumberOfKilometer] = useState(props.value.score ?? 0)
    const [numberOfKilometer, setNumberOfKilometer] = useState(0)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [editionSubCategoryName, setEditionSubCategoryName] = useState('')
    const [editionSubCategoryNameWindowVisible, setEditionSubCategoryNameWindowVisible] = useState(false)

    useEffect(() => {
        setTotalNumberOfKilometer(props.value.score ?? 0)
    }, [props.value.score])

    function isNumeric(value) {
        return /^[+-]?\d+(\.\d+)?$/.test(value);
    }

    function deleteItem() {
        deleteSubCategory(props.value.id).then(async response => {
            if(response.status === 200) {
                setDeleteModalVisible(false)
                props.deleteSubCategory(props.value.id)
            }
        })
    }

    function validateKilometers() {
        addKilometersRequest(numberOfKilometer * 1000, props.value.id).then(async response => {
            if(response.status === 200) {
                const responseData = await response.json()
                setNumberOfKilometer(0)
                setTotalNumberOfKilometer(responseData.result)

                if(props.isEditMode) {
                    props.updateSubCategoryKilometers(props.value.id, responseData.result)
                }
            }
        })
    }

    function saveNewCategoryName() {
        if(editionSubCategoryName.length > 0) {
            editSubCategoryName(editionSubCategoryName, props.value.id).then(async response => {
                if(response.status === 200) {
                    setName(editionSubCategoryName)
                    setEditionSubCategoryNameWindowVisible(false)
                } else {
                    alert("Erreur lors de l'édition du nom de la categorie")
                }
            })
        }
    }

    return (<Card
            variant={"elevation"}
            style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                margin: '15px',
                padding: 20,
                textAlign: 'center',
                backgroundColor: '#e0e0e0',
                minWidth: "5em"
            }}
        >
            <span style={{fontSize: 17}}>{name}</span>
            <p style={{fontWeight: 'bold'}}>{totalNumberOfKilometer / 1000} km</p>

            {
                props.isEditMode && <div style={{display: "flex", flexDirection: "column", alignItems: 'center'}}>
                    <TextField
                        value={numberOfKilometer}
                        type={"number"}
                        inputProps={{
                            step: "0.001"
                        }}
                        placeholder={"Nombre de km"}
                        onChange={event => {
                            const value = event.target.value
                            setNumberOfKilometer(value)
                            //setNumberOfKilometer(!isNumeric(value) ? 0 : parseFloat(value))
                        }}
                        style={{width: "50%"}}
                    />
                    {
                        isNumeric(numberOfKilometer) && numberOfKilometer !== 0 && <Button onClick={() => validateKilometers()}>Soumettre</Button>
                    }
                </div>
            }

            {
                props.isEditMode && <EditIcon style={{position: "absolute", left: 5, top: 5, color: 'blue'}} fontSize={"medium"} onClick={() => {
                        setEditionSubCategoryNameWindowVisible(true)
                    }}
                />
            }
            

            {
                props.isEditMode && <DeleteIcon style={{position: "absolute", right: 5, top: 5, color: 'red'}} onClick={() => {
                    setDeleteModalVisible(true)
                }} />
            }

            <CustomModal
                isVisible={editionSubCategoryNameWindowVisible}
                setIsVisible={setEditionSubCategoryNameWindowVisible}
            >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Changement de nom de du groupe: {name}
                </Typography>
                <TextField value={editionSubCategoryName} onChange={(event) => setEditionSubCategoryName(event.target.value)} placeholder={"Nom"} />
                <Button onClick={() => saveNewCategoryName()}>Valider</Button>
            </CustomModal>

            <CustomModal
                isVisible={deleteModalVisible}
                setIsVisible={setDeleteModalVisible}
            >
                <Typography id="modal-modal-title" variant="h6" component="h4">
                   Voulez-vous vraiment supprimer la classe {name} ?
                </Typography>
                <Button onClick={() => deleteItem()}>Valider</Button>
            </CustomModal>
        </Card>
    )
}
