import {useContext} from "react";
import {UserContext} from "../../../context/Context";
import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";

const { Link } = require("react-router-dom");
const Menu = () => {
    const {user, setUser} = useContext(UserContext)
    const navigate = useNavigate();

    function logout() {
        setUser(null)
        sessionStorage.setItem("token", null)
        navigate('/')
    }

    return (
        <nav style={{ textAlign: "center", paddingTop: "20px" }}>
            <Link to="/" style={{ padding: "10px"}}>
                Accueil
            </Link>
            <Link to="/categories" style={{ padding: "10px"}}>
                Groupes de classes
            </Link>
            {
                user && <Button onClick={logout}>Déconnexion</Button>
            }
        </nav>
    );
};
export default Menu;
