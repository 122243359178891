import {API_URL} from "../constants";


export const loginRequest = async (email, password) => {
    const body = JSON.stringify({email : email, password : password});
    try {
        return await fetch(API_URL + '/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body,
        });
    } catch (error) {
        console.error(error);
    }
};
