import React, {useState} from 'react';

export const UserContext = React.createContext({
    user: null,
    setUser: () => {},
});

export function UserProvider(props) {
    const [user, setUser] = useState(null);

    return (
        <UserContext.Provider value={{user, setUser}}>
            {props.children}
        </UserContext.Provider>
    );
}
