import './App.css';
import {useContext, useEffect, useState} from "react";

import {BrowserRouter, Route, Routes} from "react-router-dom";
import {UserContext} from "./context/Context";
import Menu from "./views/navigation/menu/Menu";
import Protected from "./views/navigation/Protected";
import {HomeView} from "./views/home/Home";
import {LoginView} from "./views/login/LoginView";
import {CategoriesView} from "./views/categories/CategoriesView";
import {CategoryView} from "./views/categories/CategoryView";

function App() {
    const {user, setUser} = useContext(UserContext)

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        document.title = "Défis: À Mangin, j'y vais autrement"
    }, [])

    useEffect(() => {
        if (isLoading) {
            const customToken = sessionStorage.getItem("token")
            if (customToken !== null && customToken !== "null") {
                setUser({token: customToken})
            } else {
                setUser(null)
            }
            setIsLoading(false)
        }
    }, [isLoading, setUser])

    return (!isLoading ? <BrowserRouter>
                <div className="App">
                    {
                        user && <Menu/>
                    }
                    <Routes>
                        <Route path='/' element={
                            <HomeView/>
                        }
                        />
                        <Route
                            path={'/login'}
                            element={
                                <LoginView/>
                            }
                        />
                        <Route
                            path={'/categories'}
                            element={
                                <Protected user={user}>
                                    <CategoriesView />
                                </Protected>
                            }
                        />
                        <Route
                            path={'/category/:id'}
                            element={
                                <Protected user={user}>
                                    <CategoryView />
                                </Protected>
                            }
                        />
                    </Routes>
                </div>
            </BrowserRouter>
            :
            <></>
    );
}

export default App;
