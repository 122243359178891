import {API_URL} from "../constants";

export const getAllCategories = async () => {
    const token = sessionStorage.getItem("token");
    try {
        return await fetch(API_URL + '/categories', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
        });
    } catch (error) {
        console.error(error);
    }
};

export const addCategoryRequest = async (name) => {
    const body = JSON.stringify({name : name});
    const token = sessionStorage.getItem("token");
    try {
        return await fetch(API_URL + '/category', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body,
        });
    } catch (error) {
        console.error(error);
    }
};

export const getSubCategories = async (categoryId) => {
    const token = sessionStorage.getItem("token");
    try {
        return await fetch(API_URL + '/category/' + categoryId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
        });
    } catch (error) {
        console.error(error);
    }
};

export const addSubCategoryRequest = async (name, categoryId) => {
    const body = JSON.stringify({name : name, categoryId: categoryId});
    const token = sessionStorage.getItem("token");
    try {
        return await fetch(API_URL + '/subcategory', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body,
        });
    } catch (error) {
        console.error(error);
    }
};

export const addKilometersRequest = async (kilometers, subCategoryId) => {
    const body = JSON.stringify({newKilometers : kilometers});
    const token = sessionStorage.getItem("token");
    try {
        return await fetch(API_URL + '/subcategory/' + subCategoryId + '/kilometers', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body,
        });
    } catch (error) {
        console.error(error);
    }
};

export const deleteCategory = async (categoryId) => {
    const token = sessionStorage.getItem("token");
    try {
        return await fetch(API_URL + '/category/' + categoryId, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
        });
    } catch (error) {
        console.error(error);
    }
};

export const deleteSubCategory = async (subCategoryId) => {
    const token = sessionStorage.getItem("token");
    try {
        return await fetch(API_URL + '/subcategory/' + subCategoryId, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
        });
    } catch (error) {
        console.error(error);
    }
};

export const editCategoryName = async (name, categoryId) => {
    const body = JSON.stringify({name : name});
    const token = sessionStorage.getItem("token");
    try {
        return await fetch(API_URL + '/category/' + categoryId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body,
        });
    } catch (error) {
        console.error(error);
    }
};

export const editSubCategoryName = async (name, subCategoryId) => {
    const body = JSON.stringify({name : name});
    const token = sessionStorage.getItem("token");
    try {
        return await fetch(API_URL + '/subcategory/' + subCategoryId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body,
        });
    } catch (error) {
        console.error(error);
    }
};
