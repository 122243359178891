import {useContext, useEffect, useState} from "react";
import '../css/Common.css';
import {loginRequest} from "../../requests/login/login_request";
import {UserContext} from "../../context/Context";
import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";

export const LoginView = () => {
    const {user, setUser} = useContext(UserContext)
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        if(user !== null && user.token !== null) {
            navigate("/")
        }
    }, [navigate, user])

    const handleUsernameChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        loginRequest(email, password).then(async response => {
            if(response.status === 200) {
                const responseData = await response.json()
                const generatedToken = responseData.token
                //TODO sauvegarde en session du token
                sessionStorage.setItem("token", generatedToken)
                setUser({token: generatedToken})
            } else {
                alert("Mot de passe invalide")
            }
        })
    };

    return (<div className={"FlexFullCenter"}>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="email">Email :</label>
                    <input
                        type="text"
                        id="email"
                        value={email}
                        onChange={handleUsernameChange}
                    />
                </div>
                <div>
                    <label htmlFor="password">Mot de passe :</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                </div>
                <Button type="submit">Se connecter</Button>
            </form>
    </div>
    );
}
