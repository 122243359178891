import {Box, Button, Modal, TextField, Typography} from "@mui/material";

export const CustomModal = (props) => {
  return (
      <Modal
          open={props.isVisible}
          onClose={() => props.setIsVisible(false)}
          aria-labelledby="modal-modal-title"
      >
          <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
          }}>
              {
                  props.children
              }
          </Box>
      </Modal>)
}
