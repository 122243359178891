import {SubCategoryKMView} from "../categories/SubCategoryKMView";


export const AroundElementView = (props) => {

    function updateSubCategoryScore(subCategoryId, newScore) {
        props.updateSubCategoryScore(props.category.id, subCategoryId, newScore)
    }

    return(
        <div style={{display: 'flex', flexDirection: 'column', flexWrap: "wrap", justifyContent: 'center', width: (1 / (Math.floor((props.totalLength) / 2)) * 100) + "%"}}>
            <label style={{fontWeight: "bold"}}>{props.category.name}</label>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-evenly'}}>
                {
                    props.category.classes.map((classe, indexClasse) => {
                        return <SubCategoryKMView key={indexClasse} value={classe} isEditMode={false} updateSubCategoryScore={updateSubCategoryScore} />
                    })
                }
            </div>
            <label>Total : {props.category.classes.reduce((acc, curr) => acc + curr.score, 0) / 1000} KM</label>
        </div>
    )
}
