import {API_URL} from "../constants";

export const getTotalNumberKm = async (id, data) => {
    const body = JSON.stringify(data);
    try {
        return await fetch(API_URL + '/kilometers', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body,
        });
    } catch (error) {
        console.error(error);
    }
};


export const getAllCategoriesWithSubCategories = async () => {
    try {
        return await fetch(API_URL + '/categories/detail', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
    } catch (error) {
        console.error(error);
    }
}
