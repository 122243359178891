import {useEffect, useState} from "react";
import {Box, Button, Grid, TextField, Typography} from "@mui/material";
import {CustomModal} from "../common/CustomModal";
import {
    addSubCategoryRequest, deleteCategory, editCategoryName,
    getSubCategories
} from "../../requests/categories/categories_request";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {SubCategoryKMView} from "./SubCategoryKMView";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

/**
 * Affiche les sous catégories d'une catégorie. Par exemple : 5ieme 1, 5ieme 2 etc...
 * @returns {JSX.Element}
 * @constructor
 */
export const CategoryView = () => {
    const navigate = useNavigate()

    let { id } = useParams();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [name, setName] = useState(params.get('name'));

    const [openCategoriesModal, setOpenCategoriesModal] = useState(false)
    const [subcategories, setSubCategories] = useState([])
    const [newSubCategoryName, setNewSubCategoryName] = useState('')
    const [editionCategoryName, setEditionCategoryName] = useState('')
    const [editionCategoryNameWindowVisible, setEditionCategoryNameWindowVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)

    useEffect(() => {
        getSubCategories(id).then(async response => {
            if(response.status === 200) {
                const responseData = await response.json()
                setSubCategories(responseData)
            }
        })
    }, [])

    function saveNewCategoryName() {
        if(editionCategoryName.length > 0) {
            editCategoryName(editionCategoryName, id).then(async response => {
                if(response.status === 200) {
                    setName(editionCategoryName)
                    setEditionCategoryNameWindowVisible(false)
                } else {
                    alert("Erreur lors de l'édition du nom de la categorie")
                }
            })
        }
    }

    function saveNewSubCategory() {
        if(newSubCategoryName.length > 0) {
            addSubCategoryRequest(newSubCategoryName, id).then(async response => {
                if(response.status === 200) {
                    const responseData = await response.json()
                    setOpenCategoriesModal(false)
                    setSubCategories((prevState) => [
                        ...prevState,
                        {
                            ...responseData.result
                        }
                    ])
                } else {
                    alert("Erreur lors de l'ajout de la sous-catégorie")
                }
            })
        } else {
            alert("Le nom ne peut être vide")
        }
    }

    function updateSubCategoryKilometers(subcategoryId, kilometers) {
        const tmpSubCategories = [...subcategories]
        const subCategory = tmpSubCategories.find((item) => item.id === subcategoryId)

        subCategory.score = kilometers
        setSubCategories(tmpSubCategories)
    }

    function deleteSubCategory(subCategoryId) {
        const tmpSubCategories = [...subcategories]

        setSubCategories(tmpSubCategories.filter(value => value.id !== subCategoryId))
    }

    function deleteItem() {
        deleteCategory(id).then(async response => {
            if(response.status === 200) {
                setDeleteModalVisible(false)
                navigate("/categories")
            }
        })
    }

    return <div style={{position: "relative", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
        <h1>Classes du niveau groupe : {name}</h1>
        <label>Total : {subcategories.reduce((acc, curr) => acc + curr.score, 0) / 1000} KM</label>
        <Button onClick={() => setOpenCategoriesModal(true)}>Créer une classe</Button>
        {
            subcategories.length > 0 &&
                <Grid container columns={3} style={{justifyContent: 'center'}}>
                    {
                        subcategories.map((value, index) => {
                            return <Grid item={true} key={index} md={1}>
                                <SubCategoryKMView
                                    value={value}
                                    isEditMode={true}
                                    updateSubCategoryKilometers={updateSubCategoryKilometers}
                                    deleteSubCategory={deleteSubCategory}
                                />
                            </Grid>
                        })
                    }
                </Grid>
        }

        <EditIcon style={{position: "absolute", left: 5, top: 5, color: 'blue'}} fontSize={"large"} onClick={() => {
                setEditionCategoryNameWindowVisible(true)
            }}
        />

        <DeleteIcon style={{position: "absolute", right: 5, top: 5, color: 'red'}} fontSize={"large"} onClick={() => {
                setDeleteModalVisible(true)
            }}
        />

        <CustomModal
            isVisible={openCategoriesModal}
            setIsVisible={setOpenCategoriesModal}
        >
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Nouvelle classe
            </Typography>
            <TextField value={newSubCategoryName} onChange={(event) => setNewSubCategoryName(event.target.value)} placeholder={"Nom"} />
            <Button onClick={() => saveNewSubCategory()}>Valider</Button>
        </CustomModal>

        <CustomModal
            isVisible={editionCategoryNameWindowVisible}
            setIsVisible={setEditionCategoryNameWindowVisible}
        >
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Changement de nom de du groupe: {name}
            </Typography>
            <TextField value={editionCategoryName} onChange={(event) => setEditionCategoryName(event.target.value)} placeholder={"Nom"} />
            <Button onClick={() => saveNewCategoryName()}>Valider</Button>
        </CustomModal>

        <CustomModal
            isVisible={deleteModalVisible}
            setIsVisible={setDeleteModalVisible}
        >
            <Typography id="modal-modal-title" variant="h6" component="h4">
                Voulez-vous vraiment supprimer le groupe de classe {name} ?
            </Typography>
            <Button onClick={() => deleteItem()}>Valider</Button>
        </CustomModal>
    </div>
}
