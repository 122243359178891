import '../css/Common.css';
import {useEffect, useState} from "react";
import {getAllCategoriesWithSubCategories, getTotalNumberKm} from "../../requests/total_km/getTotalNumberKm";
import {API_URL} from "../../requests/constants";
import {AroundElementView} from "./AroundElementView";

/**
 * Page d'accueil qui affiche le nombre total de pas
 * @returns {JSX.Element}
 * @constructor
 */
export const HomeView = () => {
    const [distanceData] = useState([
        {
            label : "Sarrebourg - Phalsbourg : 22 km",
            value: 22000
        },
        {
            label : "Marseille - Aix-en-Provence : 30 km",
            value: 30000
        },
        {
            label : "Nice - Cannes : 32 km",
            value: 32000
        },
        {
            label : "Lille - Arras : 42 km",
            value: 42000
        },
        {
            label : "Lyon - Saint-Étienne : 53 km",
            value: 53000
        },
        {
            label : "Bordeaux - Arcachon : 57 km",
            value: 57000
        },
        {
            label : "Toulouse - Carcassonne : 87 km",
            value: 87000
        },
        {
            label : "Paris - Amiens : 100 km",
            value: 100000
        },
        {
            label : "Sarrebourg - Paris : 448 km",
            value: 448000
        },
        {
            label : "Sarrebourg - Lyon : 475 km",
            value: 475000
        },
        {
            label : "Sarrebourg - Marseille : 786 km",
            value: 786000
        },
        {
            label : "Sarrebourg - Toulouse : 924 km",
            value: 924000
        },
        {
            label : "Objectif Lune ? ",
            value: 1000000000000000
        },
    ])
    const [totalNumberOfKM, setTotalNumberOfKM] = useState(0)
    const [categories, setAllCategories] = useState([])

    useEffect(() => {
        refreshCategoriesAndSubCategories()

        setTimeout(() => {
            window.location.reload();
        }, 20000)

        /*
        const sse = new EventSource(API_URL + '/live_kilometers', { withCredentials: true });
        sse.addEventListener("kilometer_update", function (event) {
            setTotalNumberOfKM(JSON.parse(event.data).value)
        });

        sse.addEventListener("refresh_main_screen", function (event) {
            refreshCategoriesAndSubCategories()
        });

        sse.addEventListener("specific_kilometer_update", function (event) {
            const result = JSON.parse(event.data)
            updateSubCategoryScore(result.subcategory_id, result.value)
        });

         */
    }, [])

    //Récupération de toutes les sous catégories
    function refreshCategoriesAndSubCategories() {
        //Récupération du nombre total de km
        getTotalNumberKm().then(async response => {
            if(response.status === 200) {
                const dataResponse = await response.json();
                setTotalNumberOfKM(dataResponse.result)
            }
        })

        getAllCategoriesWithSubCategories().then(async response => {
            if(response.status === 200) {
                const dataResponse = await response.json();
                setAllCategories(dataResponse)
            }
        })
    }

    function updateSubCategoryScore(subCategoryId, newScore) {
        if(categories.length === 0) {
           refreshCategoriesAndSubCategories()
        } else {
            const tmpCategories = [...categories]

            const category = tmpCategories.find(value => value.classes.some(item => item.id === subCategoryId))
            if(category) {
                const subCategory = category.classes.find((item) => item.id === subCategoryId)

                subCategory.score = newScore
                setAllCategories(tmpCategories)
            }
        }
    }

    return (
      <div className={'FlexFullCenter'} style={{padding: 10}}>
          {
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', gap: 50}}>
                  {categories.slice(0, Math.floor((categories.length) / 2)).map((category, index) => (
                      <AroundElementView key={index} category={category} index={index} totalLength={categories.length} updateSubCategoryScore={updateSubCategoryScore} />
                  ))}
              </div>
          }

          <p style={{fontSize: 40, fontWeight: "bold", border: 'solid', borderStyle: 'dashed', borderColor: 'black', padding: 20}}><span style={{fontSize: 60}}>{totalNumberOfKM / 1000} KM</span> PARCOURUS !</p>
          <span style={{fontSize: 25}}>Prochain objectif : {distanceData.filter(value => value.value > totalNumberOfKM)[0].label}</span>

          <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', gap: 50, marginTop: 50}}>
              {categories.slice(-Math.ceil((categories.length) / 2)).map((category, index) => (
                  <AroundElementView key={index} category={category} index={index} totalLength={categories.length} updateSubCategoryScore={updateSubCategoryScore} />
              ))}
          </div>
      </div>
  )
}
