import {useEffect, useState} from "react";
import {Box, Button, Card, Grid, TextField, Typography} from "@mui/material";
import {CustomModal} from "../common/CustomModal";
import {addCategoryRequest, getAllCategories} from "../../requests/categories/categories_request";
import {useNavigate} from "react-router-dom";

export const CategoriesView = () => {
    const [openCategoriesModal, setOpenCategoriesModal] = useState(false)
    const [newCategoryName, setNewCategoryName] = useState('')

    const [categories, setCategories] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        getAllCategories().then(async response => {
            if(response.status === 200) {
                const responseData = await response.json()
                setCategories(responseData)
            }
        })
    }, [])

    function saveNewCategory() {
        if(newCategoryName.length > 0) {
            addCategoryRequest(newCategoryName).then(async response => {
                if(response.status === 200) {
                    const responseData = await response.json()
                    setOpenCategoriesModal(false)
                    setCategories((prevState) => [
                        ...prevState,
                        {
                            ...responseData.result
                        }
                    ])
                } else {
                    alert("Erreur lors de l'ajout de la catégorie")
                }
            })
        } else {
            alert("Le nom ne peut être vide")
        }
    }

    return <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <h1>Groupes de classes</h1>
        <Button onClick={() => setOpenCategoriesModal(true)}>Créer un groupe de classes (ex: 6ièmes)</Button>
        {
            categories.length > 0 && <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={8} columns={4}>
                    {
                        categories.map((value, index) => {
                            const path = "/category/"+ value.id + "?name=" + value.name
                            return <Grid key={index} item={true} md={1}>
                                        <Card
                                            variant={"elevation"}
                                            style={{
                                                margin: '15px',
                                                textAlign: 'center',
                                                backgroundColor: '#e0e0e0',
                                                minWidth: "100px"
                                            }}
                                            onClick={() => navigate(path)}
                                        >
                                            <p>{value.name}</p>
                                        </Card>
                                </Grid>
                        })
                    }
                </Grid>
            </Box>
        }


        <CustomModal
            isVisible={openCategoriesModal}
            setIsVisible={setOpenCategoriesModal}
        >
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Nouveau groupe de classes
            </Typography>
            <TextField value={newCategoryName} onChange={(event) => setNewCategoryName(event.target.value)} placeholder={"Nom"} />
            <Button onClick={() => saveNewCategory()}>Valider</Button>
        </CustomModal>

    </div>
}
